export default {
  proxy_path: "https://6syyvvf0mi.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://fdru8m28za.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyAL4HCOqiH0J_AHjY5H5Xr3_XzfGyTSQ_g",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.nsr.forwoodsafety.com",
  forwood_id_url: "https://id.staging.nsr.forwoodsafety.com?redirect_uri=https://mapreport.staging.nsr.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.nsr.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_IpiiUgpqZ",
    userPoolWebClientId: "4f500mpir5rt3f9jb0ls92unar",
    cookieStorage: {
      domain: ".staging.nsr.forwoodsafety.com",
      secure: true
    }
  }
};
